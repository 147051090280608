<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Using the data available in
        <a
          href="https://ecampusontario.pressbooks.pub/genchemforgeegees/chapter/appendix-h-ionization-constants-of-weak-acids/"
          rel="noopener noreferrer"
          target="_blank"
          >Appendix H</a
        >
        of the textbook Gen Chem for Gee Gees, calculate
        <stemble-latex content="$\text{K}_\text{eq}$" /> for the following reaction.
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex :content="balancedEquation" />
      </p>

      <calculation-input
        v-model="inputs.Keq"
        class="mb-0"
        prepend-text="$\text{K}_\text{eq}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question467',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Keq: null,
      },
    };
  },
  computed: {
    version() {
      return this.taskState.getValueBySymbol('version').content;
    },
    balancedEquation() {
      if (this.version.number === 1) {
        return 'H2PO4-(aq) + OH-(aq) <=> HPO4^2-(aq) + H2O(l)';
      } else if (this.version.number === 2) {
        return 'HPO4^2-(aq) + OH-(aq) <=> PO4^3-(aq) + H2O(l)';
      } else if (this.version.number === 3) {
        return 'H2AsO4-(aq) + OH-(aq) <=> HAsO4^2-(aq) + H2O(l)';
      } else {
        return 'HAsO4^2-(aq) + OH-(aq) <=> AsO4^3-(aq) + H2O(l)';
      }
    },
  },
};
</script>
